import { get } from 'react-hook-form';

type SiteIdParams = {
  siteId: string;
};

export const linkHelper = {
  login: {
    path: '/login',
    getLink: () => '/login',
  },
  postLogin: {
    path: '/post-login',
    getLink: () => '/post-login',
  },
  loginWpsitecontrol: {
    path: '/login/wpsitecontrol',
    getLink: () => '/login/wpsitecontrol',
  },
  logout: {
    path: '/logout',
    getLink: () => '/logout',
  },
  setPassword: {
    path: '/set-password',
    getLink: ({ token, email, userId }: { token: string; email: string; userId: string }) =>
      `/set-password?token=${token}&email=${encodeURIComponent(email)}&user_id=${userId}`,
  },
  resetPassword: {
    path: '/reset-password',
    getLink: () => '/reset-password',
  },
  manageWordpress: {
    path: '/manage/:siteId/wordpress',
    getLink: ({ siteId }: SiteIdParams) => `/manage/${siteId}/wordpress`,
  },
  managePhpadmin: {
    path: '/manage/:siteId/phpmyadmin',
    getLink: ({ siteId }: SiteIdParams) => `/manage/${siteId}/phpmyadmin`,
  },
  profile: {
    path: '/profile/*',
    getLink: () => '/profile',
    changePassword: {
      path: 'change-password',
      getLink: () => '/profile/change-password',
    },
    twoFactorAuthentication: {
      path: '2fa',
      getLink: () => '/profile/2fa',
    },
  },

  dashboard: {
    path: '/dashboard',
    getLink: () => '/dashboard',
  },
  users: {
    path: '/users',
    getLink: () => '/users',
    accept: {
      path: 'accept',
      getLink: () => '/users/accept',
    },
  },
  tickets: {
    path: '/tickets',
    getLink: () => '/tickets',
    create: {
      path: 'create',
      getLink: () => '/tickets/create',
    },
  },
  sites: {
    path: '/sites/*',
    getLink: () => '/sites',
    inviteAccept: {
      path: ':siteId/invite-accept',
      getLink: ({ siteId }: SiteIdParams) => `'/sites/:siteId/invite-accept`,
    },
    siteDetails: {
      path: ':siteId/*',
      tabs: {
        path: ':tabName/*',
      },
    },
    create: {
      path: 'create',
      getLink: ({ templateId }: { templateId?: string }) => {
        if (templateId) {
          return `/sites/create?templateId=${templateId}`;
        }
        return '/sites/create';
      },
    },
    templates: {
      path: 'templates',
      getLink: () => `/sites/templates`,
    },
    viewTemplate: {
      path: 'templates/:templateId',
      getLink: ({ templateId }: { templateId: string }) => `/sites/templates/${templateId}`,
    },
  },
  // this will end up being a replica of the router for the new ux
  newDesign: {
    path: '/n/*',
    getLink: () => '/n',
    dashboard: {
      path: 'dashboard',
      getLink: () => '/n/dashboard',
    },
    sites: {
      path: '/sites/*',
      getLink: () => '/n/sites',
      templates: {
        path: 'templates',
        getLink: () => `/n/sites/templates`,
      },
      viewTemplate: {
        path: 'templates/:templateId',
        getLink: ({ templateId }: { templateId: string }) => `/n/sites/templates/${templateId}`,
      },
      siteDetails: {
        path: ':siteId/*',
        getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}`,
        overview: {
          path: 'overview',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/overview`,
        },
        plugins: {
          path: 'plugins',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/plugins`,
        },
        themes: {
          path: 'themes',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/themes`,
        },
        fileManager: {
          path: 'file-manager',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/file-manager`,
        },
        backups: {
          path: 'backups',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/backups`,
        },
        logs: {
          path: 'logs',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/logs`,
          activityLog: {
            getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/logs`,
          },
          accessLog: {
            path: 'access-log',
            getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/logs/access-log`,
          },
        },
        reporting: {
          path: 'reporting',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/reporting`,
          overview: {
            getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/reporting`,
          },
          delivery: {
            path: 'delivery',
            getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/reporting/delivery`,
          },
        },
        security: {
          path: 'security',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/security`,
        },
        advanced: {
          path: 'advanced',
          getLink: ({ siteId }: SiteIdParams) => `/n/sites/${siteId}/advanced`,
        },
      },
    },
    settings: {
      path: 'settings/*',
      getLink: () => '/n/settings',
      accountAndSecurity: {
        path: 'account-and-security',
        getLink: () => `/n/settings/account-and-security`,
      },
      billing: {
        path: 'billing',
        getLink: () => '/n/settings/billing',
      },
      users: {
        path: 'users',
        getLink: () => '/n/settings/users',
        accept: {
          path: 'accept',
          getLink: () => '/n/settings/users/accept',
        },
      },
      siteTemplates: {
        path: 'templates',
        getLink: () => '/n/settings/templates',
        viewTemplate: {
          path: 'templates/:templateId',
          getLink: ({ templateId }: { templateId: string }) =>
            `/n/settings/templates/${templateId}`,
        },
      },
    },
  },
};
